.authentication-page__wrapper .ui.label {
    display: flex;
    align-items: center;
}

.invitation .toggle input + label {
    border: none !important;
}

.invitation .toggle input:checked + label {
    border: none;
}

.invitation .field .label {
    margin-bottom: 0.35rem;
}

.companyCard .description .ui.header:first-child {
    margin-top: 1px;
}

.ui.pagination.menu {
    padding: 0;
}

.quotation-status .toggle input + label {
    border: none;
}

.quotation-status, .order-status {
    display: flex;
    align-items: center;
    margin: 8px 8px;
    height: 33px;
    max-height: 33px;
}

.order-status>button {
    min-width: 155px;
}

.quotation-status.dropdown.ui.button, .order-status.dropdown.ui.button {
    margin: 0 8px;
}

.quotation-status.dropdown.ui.button input , .order-status.dropdown.ui.button input {
    caret-color: transparent;
    cursor: pointer !important;
}

.quotation-status input, .order-status input {
    padding-top: 7px !important;
    padding-bottom: 7px !important;
}

.order-status .toggle input + label {
    border: none;
}
